/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onClientEntry = () => {
  // Importa bootstrap solo lato client
  import('bootstrap').then(bootstrap => {
    window.bootstrap = bootstrap;
  });

  // Aggiungi logger per gli errori di hydration in development
  if (process.env.NODE_ENV === 'development') {
    const originalError = console.error;
    console.error = (...args) => {
      if (args[0]?.includes('Hydration')) {
        console.log('Hydration error details:', args);
      }
      originalError.apply(console, args);
    };
  }
};
